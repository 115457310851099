<template>
  <div>
    <dialogTitle title="Download Link">
      <v-btn
        icon
        dark
        @click="
          $store.commit('closeDialog', result.dialogRef);
          $emit('close-dialog');
        "
      >
        <v-icon>mdi-close</v-icon>
      </v-btn></dialogTitle
    >
    <v-card>
      <v-card-text>
        <div class="text-body-1">
         

          <p>
            We have successfully customized and created your Degust Explorer OS
            image.
          </p>

          <p>
            It is available for download as a zip file for 60 minutes and will
            be deleted afterward. You can download it to your system and install
            and use it as many times as you need.
          </p>

          <p>
            These images have been intensively tested on the original Raspberry
            Pi 3 B+ and Pi 4. Please keep in mind that the Pi 4 will be the only
            option for testing speeds up to Gigabit Ethernet, as it may be
            required for Gigabit tariffs such as FTTH.
          </p>

          <p>
            Systems on which this image is installed and started will work in
            all projects configured in your organization.
          </p>
       
          <p class="text-center">  <a id="explorer_download_link" class="mr-5" :href="result.link">Download Now</a>  <a target="_blank" href="https://service.acctopus.com/portal/en/kb/articles/install-degust-explorer-on-sd-card">Read full instructions
          </a></p>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import dialogTitle from "@/commonComponents/dialogTitle.vue";

export default {
  props: ["result"],
  components: {
    dialogTitle,
  },
};
</script>